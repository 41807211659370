<template>
  <div class="table-box">
    <div class="table-title">
      <div class="cell" v-for="(t, k) in titles" :key="k">{{ t.title }}</div>
    </div>
    <div class="table-items">
      <div class="item" v-for="(i, k) in datas" :key="k">
        <template v-for="(t, k2) in titles">
          <div
            class="cell cell-no"
            :class="'index-' + (k + 1)"
            :key="k2"
            v-if="t.title === 'Ranking'"
          >
            <template v-if="k > 2">
              {{ k + 1 }}
            </template>
            <SvgIcon icon="king" v-else></SvgIcon>
          </div>
          <div
            class="cell"
            :class="t.class"
            :key="k2"
            v-html="t.html(i)"
            v-else-if="t.html"
          ></div>
          <div class="cell" :class="t.class" :key="k2" v-else>
            {{ i[t.name] }}
          </div>
        </template>
      </div>
    </div>
    <div class="pager-box" v-if="pager.show">
      <div
        class="next-page"
        :class="{ disabled: !pager.prev }"
        @click="pager.prev ? getNewData(-1) : ''"
      >
        <svg-icon icon="arrow"></svg-icon>
        Prev Page
      </div>
      <div
        class="next-page"
        :class="{ disabled: !pager.next }"
        @click="pager.next ? getNewData(1) : ''"
      >
        Next Page
        <svg-icon icon="arrow"></svg-icon>
      </div>
    </div>
    <div class="table-img">
      <img
        class="loading"
        :src="require('@/assets/imgs/loading.png')"
        alt=""
        v-if="loading"
      />
      <img
        class="no-data"
        :src="require('@/assets/imgs/no-data.png')"
        alt=""
        v-else-if="datas && !datas.length"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted } from "vue";
import usePager from "@/hooks/usePager";

const props = defineProps({
  titles: Array,
  api: Function,
  limit: Number,
  noPager: Boolean,
  params: Object
});
const { datas, pager, loading, getProData, getNewData } = usePager(
  props.api,
  props.limit || 10,
  props.noPager || false,
  props.params || {}
);

onMounted(() => {
  getProData();
});
</script>
