<template>
  <div class="home">
    <div class="info-box" v-if="$store.state.connection !== ''">
      <img class="logo" src="~@/assets/imgs/logo-text.png" alt="" />
      <button @click="showWallet = true">Connect Wallet</button>
    </div>
    <div class="info-box account" v-else>
      <div class="credits">
        <div class="credits-title">Credits Account</div>
        <h1>{{ $store.state.user.coins }} XM</h1>
        <div class="credits-text">≈ $67808</div>
      </div>
      <button @click="showRecharge = true">Top-Up</button>
    </div>
    <div class="market-box">
      <h3>Market</h3>
      <table-box :titles="titles" :api="api"></table-box>
    </div>
    <modal-box
      title="Select a Wallet"
      :showClose="true"
      @close="showWallet = false"
      v-if="showWallet"
    >
      <div class="btn" @click="connect('Metamask')">
        <img src="~@/assets/imgs/metamask.png" alt="" />
        Metamask
      </div>
      <div class="btn" @click="connect('WalletAPP')">
        <img src="~@/assets/imgs/ws.png" alt="" />
        Wallet Connect
      </div>
      <div class="btn" @click="connect('TON')">
        <img src="~@/assets/imgs/ton.png" alt="" />
        TON
      </div>
    </modal-box>
    <modal-box
      v-if="showSign"
      class="sign-modal"
      title="Signature Request"
      :showClose="false"
      @close="showSign = false"
    >
      <div class="sign-box">
        <img class="modal-icon" src="~@/assets/imgs/warning.png" />
        <p>
          Only sign this message if you fully understand the content and trust
          the requesting site.
        </p>
        <div class="btns">
          <button class="confirm" @click="sign">Confirm</button>
        </div>
      </div>
    </modal-box>
    <recharge-modal
      v-if="showRecharge"
      @close="showRecharge = false"
    ></recharge-modal>
  </div>
</template>

<script>
import RechargeModal from "@/components/frame/RechargeModal.vue";
import { walletSign, walletConnect } from "@/wallet";
import { getItem, setStorage } from "@/wallet/utils";
import api from "@/api";
import { toUserFriendlyAddress } from "@tonconnect/ui";

export default {
  name: "Home",
  data() {
    return {
      showWallet: false,
      showSign: false,
      checkTimes: 1,
      showRecharge: false,
      // coins: [],
      titles: [
        {
          title: "Coin",
          class: "coin",
          html: (i) => {
            return `
              <img src="${require("@/assets/imgs/" + i.coin + ".png")}" alt="" />
              <div class="coin-name">
                <div class="name">${i.coin}</div>
                <div class="desc">${i.name}</div>
              </div>
            `;
          }
        },
        { title: "Price", class: "price", name: "price" },
        {
          title: "Rate",
          html: (i) => {
            return `<div class="rate ${i.s ? "green" : "red"}">${i.rate}</div>`;
          }
        }
      ],
      api: api.getCoins
    };
  },
  methods: {
    async connect(type) {
      this.showWallet = false;
      if (type === "TON") {
        api.getPayload().then(async (res) => {
          console.log(res.payload);
          this.tonConnectUI.setConnectRequestParameters({
            state: "ready",
            value: {
              tonProof: res.payload
            }
          });
          await this.tonConnectUI.openModal();
          console.log(this.tonConnectUI.wallet);
        });
      } else {
        walletConnect(type)
          .then((res) => {
            console.log(res);
            if (res === "sign") {
              // setLoading(false);
              // setShowSign(true);
              this.showSign = true;
            } else {
              this.$store.commit("updateUser");
              // const res = await updateUser();
              // // console.log(res);
              // if (!res) {
              //   localStorage.clear();
              //   return console.error(
              //     "Signature verification failed, please try again later"
              //   );
              // }
              // navigate("/");
            }
          })
          .catch((e) => {
            // setLoading(false);
            console.error(e);
          });
      }
    },
    async sign() {
      // setLoading(true);

      const address = getItem("account") || "";
      const res = await api.getNonce({ address });
      // console.log(res);

      walletSign(address, res.Nonce)
        .then(async (res) => {
          console.log(res);

          this.showSign = false;
          this.$store.commit("updateUser");
        })
        .finally(() => {
          // setLoading(false);
        })
        .catch(console.error);
    },
    checkProof(data) {
      // setLoading(true);
      api
        .checkProof(data)
        .then(async (res) => {
          console.log(res);
          const token = res.token;
          const address = toUserFriendlyAddress(data.address, true);
          const signData = JSON.parse(getItem("signature") || "{}") || {};
          setStorage([
            ["client", "TON"],
            ["account", address],
            ["chain", data.network === "-3" ? "1101" : "1100"],
            [
              "signature",
              JSON.stringify({
                ...signData,
                [address]: { token, address }
              })
            ]
          ]);
          // setLoading(false);
          this.$store.commit("updateUser");
          // navigate("/");
        })
        .catch((e) => {
          console.log(e);
          // if (e.includes("timeout") && this.checkTimes < 3) {
          //   this.checkTimes += 1;
          //   this.checkProof(data);
          // } else {
          //   // e: "not active", "Wrong network", "timeout" && checkTimes >= 3
          //   localStorage.clear();

          //   if (this.checkTimes < 3) console.error(e);
          //   else console.error("Server error, please try again later");
          //   // setTimeout(() => {
          //   //   location.reload();
          //   // }, 2000);
          // }
        });
    }
  },
  watch: {
    "$store.state": {
      handler(v) {
        this.showSign = v.connection === "sign";
      },
      immediate: true
    }
  },
  mounted() {
    // console.log(this.tonConnectUI.account);
    // const unsubscribe =
    this.tonConnectUI.modal.onStateChange((state) => {
      console.log(state);
      // console.log(this.tonConnectUI.wallet);
      const wallet = this.tonConnectUI.wallet;
      if (wallet) {
        if (this.tonConnectUI.wallet.connectItems?.tonProof) {
          // if (!loading) {}
          const data = {
            address: wallet.account.address,
            network: wallet.account.chain,
            proof: {
              ...wallet.connectItems.tonProof.proof,
              state_init: wallet.account.walletStateInit
            }
          };
          console.log(data);

          this.checkProof(data);
          // test: -3 main: -239
          // console.error("Wrong network, expect mainnet");
        }
        // else this.tonConnectUI.disconnect();
      }
    });
    // unsubscribe();

    // api.getCoins().then((res) => {
    //   this.coins = res;
    // });
  },
  inject: ["tonConnectUI"],
  components: { RechargeModal }
};
</script>
