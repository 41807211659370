import axios from "axios";
import { getSignData, clearAllData } from "@/wallet/utils";
import { platform } from "@/wallet/config";
// import store from "@/store";

// const serverError = [1001, 1002, 2009]

const errors = {
  1001: "Server error, please try again later", //服务器错误，请重试
  2009: "Server error, please try again later", //服务器错误，请重试
  9999: "Server error, please try again later", //服务器错误，请重试
  1002: "Data query failed", //数据查询失败
  1006: "Signature verification failed", //签名验证失败
  1010: "Signature has expired", //签名过期
  2003: "Insufficient credits", // 积分不足
  2043: "Unable to sign in, please ensure a 24-hour interval", //签到未满足时间
  3014: "Insufficient credits", // 积分不足
  3009: "Insufficient PCI on device",
  5006: "Wrong network, expect mainnet", // 网络错误
  5009: "Sorry, your account is not active, so it is not available" // 账户不可用
};

const http = axios.create({
  baseURL: platform.api,
  // baseURL: "http://192.168.11.33:8082/metaversenft",
  // baseURL: "https://web3authtest.xmultiverse.org/metaversenft",
  withCredentials: false,
  timeout: 300000
});

http.interceptors.request.use(
  (config) => {
    const { signature, address, message, token } = getSignData() || {};
    if (token) {
      config.headers.Authorization = token;
      config.headers.address = address;
      config.headers.chain = localStorage.getItem("chain");
    }
    if (signature) {
      config.headers.signature = signature;
      config.headers.address = address;
      config.headers.chain = localStorage.getItem("chain");
      config.headers.message = encodeURIComponent(message);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
http.interceptors.response.use(
  (res) => {
    const code = Number(res.data.code);
    if (code === 0) return Promise.resolve(res.data.data);
    else if (code === 1010) {
      clearAllData();
    } else if (errors[code]) return Promise.reject(errors[code]);
    else if (Number(code))
      return Promise.reject(
        res.data.msg || "Operation failed, please try again later"
      );
    else if (res.request.responseType === "blob")
      return Promise.resolve(res.data);
    return Promise.reject(res.data);
  },
  (err) => {
    return Promise.reject(err.message);
  }
);

export function $get(url, config) {
  return http.get(url, config);
}

export function $post(url, data, config) {
  return http.post(url, data, config);
}
