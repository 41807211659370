import Message from "@/components/Message.vue";

const message = {
  install(Vue) {
    const MessageConstructor = Vue.extend(Message);
    const instance = new MessageConstructor();
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);

    Vue.prototype.$message = {
      success: (msg) => {
        instance.open(msg, "info");
      },
      error: (msg) => {
        instance.open(msg, "error");
      },
      warn: (msg) => {
        instance.open(msg, "warn");
      }
    };
  }
};

export default message;
