<template>
  <div class="my">
    <div class="address-box">
      <div class="user">
        <svg-icon icon="user"></svg-icon>
        {{ $store.state.user.address }}
      </div>
      <div class="logout" @click="logout">Logout</div>
    </div>
    <div class="account-box">
      <div class="title have-more">
        <div class="text">Credit Account</div>
        <router-link class="more" to="/credits">Credit Records ></router-link>
      </div>
      <div class="account-credits">
        <div class="credits">
          <h1>{{ $store.state.user.coins }} XM</h1>
          <div class="credits-text">≈ $67808</div>
        </div>
        <button @click="showRecharge = true">Top-Up</button>
      </div>
    </div>
    <div class="invitation-box">
      <div class="title">My Invitations</div>
      <button @click="copyLink">
        <svg-icon icon="copy"></svg-icon>
        Copy Link
      </button>
      <p>
        Direct invitation earns 30 points, indirect invitation earns 20 points
      </p>
      <div class="invitation-title have-more">
        <div class="text">Invitation Records</div>
        <router-link class="more" to="invitations">More ></router-link>
      </div>
      <table-box
        class="line"
        :titles="titles"
        :api="api"
        :limit="5"
        :noPager="true"
        :params="{ source: 8 }"
      ></table-box>
    </div>
    <recharge-modal
      v-if="showRecharge"
      @close="showRecharge = false"
    ></recharge-modal>
  </div>
</template>

<script>
import RechargeModal from "@/components/frame/RechargeModal.vue";
import api from "@/api";
import { copyAddress } from "@/utils";
import { clearData } from "@/wallet/utils";
export default {
  name: "My",
  data() {
    return {
      showRecharge: false,
      inviteCode: "",
      titles: [
        { title: "Address", name: "address" },
        { title: "Time", name: "time" },
        { title: "Credits", name: "credit" }
      ],
      api: api.getInviteInfo
    };
  },
  components: { RechargeModal },
  watch: {},
  mounted() {
    api.getInviteInfo().then((res) => {
      this.inviteCode = res.InvCode;
    });
  },
  methods: {
    copyLink() {
      copyAddress(location.origin + "/home?code=" + this.inviteCode);
    },
    logout() {
      clearData();
      this.$router.push("/home");
      this.$store.commit("connection");
    }
  }
};
</script>
