<template>
  <modal-box
    class="recharge-modal"
    :class="{ 'loading-modal': loading }"
    :showClose="true"
    :title="payStatus === 0 ? 'Top-up' : ''"
    @close="$emit('close')"
  >
    <div v-show="!loading">
      <recharge-form
        v-if="payStatus === 0"
        @success="
          loading = false;
          payStatus = 1;
          successData = $event;
        "
        @loading="loading = $event"
      >
      </recharge-form>
      <div class="success-box" v-else-if="payStatus === 1">
        <div class="success">
          <img class="success-img" src="@/assets/imgs/success.png" alt="" />
          <img
            class="fireworks fireworks-1"
            src="@/assets/imgs/fireworks.png"
            alt=""
          />
          <img
            class="fireworks fireworks-2"
            src="@/assets/imgs/fireworks.png"
            alt=""
          />
        </div>
        <h1>Congratulations, your payment was successful</h1>
        <p>
          The total {{ successData.points }} credits purchased are equivalent to
          ${{ successData.money }}. Please refresh later, the credits will be
          added to your account soon
        </p>
      </div>
    </div>
  </modal-box>
</template>

<script>
import RechargeForm from "@/components/frame/RechargeForm.vue";
export default {
  name: "My",
  data() {
    return {
      loading: false,
      successData: {},
      payStatus: 0
    };
  },
  components: { RechargeForm },
  watch: {},
  methods: {}
};
</script>
