import { $post, $get } from "@/utils/http";
import { getAddrStr } from "@/utils";

export default {
  getPayload() {
    return $get("/proof/get");
  },
  checkProof({ address, network, proof }) {
    return $post("/proof/check", {
      network,
      proof,
      address
    });
  },
  // 获取Ton价格
  getTonPrice() {
    return $get("/proof/tonPrice");
  },
  // 充值历史
  getRecharges({ pageNo, pageSize }) {
    return $post("/aigc/coinHis", { pageNo, pageSize });
  },

  getNonce(data) {
    return $post("/address/nonce", data);
  },
  async getUserInfo(headers) {
    // const isOwn = a === store.state.user.id
    return $post("/address/info", null, { headers }).then((res) => {
      if (localStorage.getItem("code")) localStorage.removeItem("code");

      const data = {
        coins: res.Coin.toFixed(1),
        address: getAddrStr(res.Address),
        checkIn: res.SignIn,
        lastCheckIn: (res.SignTime || 0) * 1000
      };
      return data;
    });
  },
  // 签到
  checkIn() {
    return $get("/aigc/signIn");
  },

  getPointsDetail({ pageNo, pageSize }) {
    return $post("/invoice/history", { pageNo, pageSize, status: 1 });
  },

  // 币种对应U的价格
  async getCoinPrice(chain, coinId) {
    return $post("/aigc/coin", { chain, coinId }).then((res) => res.coinPrice);
  },
  // 获取充值币种(ETH)
  getRechargeCoins(chain) {
    return $post("/aigc/coinList", { chain });
  },

  // 首页行情
  getCoins() {
    return $get("/address/coinList").then((res) => {
      const res2 = Object.keys(res)
        .map((i) => ({
          name: res[i][0].name,
          coin: res[i][0].symbol,
          ...res[i][0].quote.USD
        }))
        .map((i) => ({
          name: i.name,
          coin: i.coin,
          price: "$" + i.price.toFixed(2),
          rate: i.percent_change_24h.toFixed(2) + "%",
          s: i.percent_change_24h >= 0
        }));
      return res2;
    });
  },
  // 积分排行
  getCreditRankings({ pageNo, pageSize }) {
    return $post("/address/ranking", { pageNo, pageSize }).then((res) =>
      (res || []).map((i) => ({
        ...i,
        Address: getAddrStr(i.Address),
        Coin: +i.Coin.toFixed(1)
      }))
    );
  },
  // 邀请
  getInviteInfo(
    { source, pageNo, pageSize } = { source: 8, pageNo: 1, pageSize: 5 }
  ) {
    // source 8、9
    return $post("/aigc/invite", { pageNo, pageSize, source }).then((res) =>
      (res.InvList || []).map((i) => ({
        address: getAddrStr(i.Address),
        time: new Date(i.CreateTime * 1000).toLocaleString(),
        credit: "+" + i.Num
      }))
    );
  },
  // 充值记录
  getRecharges({ pageNo, pageSize }) {
    return $post("/aigc/transfer", { pageNo, pageSize }).then((res) =>
      (res || []).map((i) => ({
        coin: "+" + +i.CoinNum.toFixed(1),
        time: new Date(i.CreateTime * 1000).toLocaleString(),
        hash: i.Hash
      }))
    );
  }
};
