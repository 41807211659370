// 配置文件
export const networks = {
  1: { name: "Ethereum", coin: "ETH" },
  11155111: { name: "Ethereum Testnet", coin: "ETH" }, // ok
  56: { name: "Bsc Mainnet", coin: "BNB" },
  97: { name: "Bsc Testnet", coin: "BNB" }, // ok
  1100: { name: "TON Mainnet", coin: "TON" }, // -239
  1101: { name: "TON Testnet", coin: "TON" } // -3
};
const config = {
  test: {
    // api: "https://web3auth.xmultiverse.org/metaversenft",
    api: "http://192.168.11.33:8082/metaversenft",
    domains: ["192.168.11.25:8000", "localhost:8000"],
    chains: Object.keys(networks)
  },
  online: {
    api: "https://web3auth.xmultiverse.org/metaversenft",
    domains: ["dapp.xmultiverse.org"],
    chains: ["1100", "56"]
  }
};

const type =
  Object.keys(config).find((i) => config[i].domains.includes(location.host)) ||
  "test";
export const platform = config[type];
