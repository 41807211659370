import { providers, utils, Contract } from "ethers";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import {
  getItem,
  clearData,
  strForLower,
  setChain,
  setStorage,
  checkConnect
} from "@/wallet/utils";

import userApi from "@/api";
import ERC20 from "./abi/ERC20.json";

let events = false;
let provider = null;

export async function getProvider(client2) {
  const client = client2 || getItem("client");
  if (client === "WalletAPP" && !provider) {
    return EthereumProvider.init({
      projectId: "8441b880a46c4e11befdd1aacb901a26",
      showQrModal: true,
      optionalChains: [1, 56, 97]
    })
      .then((p) => {
        provider = p;
        if (provider && !events) {
          events = true;

          provider.on("disconnect", async (code, reason) => {
            console.log(code, reason);
            clearData();
            location.reload();
          });
        }
        return true;
      })
      .catch(console.log);

    // provider = new Web3Provider({
    //   infuraId: 'd9a548edacbc46209c5573f083158b66',
    //   rpc: { [getChainId()]: getChain().rpc }
    // });
  } else {
    provider = window.ethereum;

    if (!events) {
      events = true;
      provider.on("accountsChanged", async (accounts) => {
        console.log(accounts);
        if (accounts.length) {
          localStorage.setItem("account", strForLower(accounts[0]));
          location.reload();
        }
      });
      provider.on("chainChanged", () => {
        console.log(provider.chainId);
        setChain(provider.chainId);
        if (events) setChain(provider.chainId);
        else events = true;
      });
    }

    return provider;
  }
}
async function getSignedProvider() {
  if (!provider) await getProvider();
  return new providers.Web3Provider(provider);
}
// wallet connect
export async function walletConnect(client) {
  async function connect() {
    await getProvider(client);
    if (client === "WalletAPP") {
      return provider.enable();
    } else if (client) {
      return provider.request({ method: "eth_requestAccounts" });
    } else {
      return Promise.resolve();
    }
  }
  return connect()
    .then((address) => {
      console.log(address);
      if (address) {
        let data = [
          ["client", client],
          ["account", strForLower(address[0])],
          ["chain", Number(provider.chainId)]
        ];
        setStorage(data);
        return checkConnect();
      }
      return false;
    })
    .catch((e) => {
      console.log(e);
      if (JSON.stringify(e).includes("rejected"))
        return Promise.reject("You have cancelled the action ");
      return Promise.reject("Connect Fail!");
    });
}

// sign
export async function walletSign(address, nonce) {
  const message =
    "Welcome to Xmultiverse!\nClick to sign in and accept the Xmultiverse Terms of Service: https://xmultiverse.zendesk.com/hc/en-us/articles/13015094824345-Terms-Of-Service\nThis request will not trigger a blockchain transaction or cost any gas fees.\nYour authentication status will reset after 24 hours.\nWallet address:\n" +
    address +
    "\nNonce: " +
    String(nonce);

  async function querySign() {
    await getProvider();
    const web3Provider = await getSignedProvider();

    return web3Provider.send("personal_sign", [message, address]);
  }
  return querySign()
    .then((signature) => {
      console.log(signature);
      const signData = JSON.parse(getItem("signature")) || {};
      setStorage([
        [
          "signature",
          JSON.stringify({
            ...signData,
            [address]: { signature, address, message }
          })
        ]
      ]);
      return true;
    })
    .catch(async (error) => {
      console.log(error.message || error);
      if (
        error.message.includes(
          "The requested account and/or method has not been authorized by the user"
        )
      ) {
        return Promise.reject(
          "The current account is not connected to the website, please connect"
        );
      } else if (error.message.includes("rejected"))
        return Promise.reject("You have cancelled the action ");
      else return Promise.reject(error.message);
    });
}
export async function checkSign(signature, message) {
  return userApi
    .getUserInfo({
      Signature: signature,
      Address: getItem("account") || "",
      Message: encodeURIComponent(message),
      Chain: getItem("chain") || ""
    })
    .catch(console.log);
}

// 转账
export async function transferCoin(to, val, token, decimals = 18) {
  const value = utils.parseUnits(val, decimals);
  let res = null;
  try {
    if (token) {
      const con = await connectContract(token, ERC20);
      // console.log(to, token, value);
      res = await con.transfer(to, value);
      console.log(res);
    } else {
      const provider = await getSignedProvider();
      const signer = provider.getSigner();
      res = await signer.sendTransaction({ to, value });
    }
    return res.wait();
  } catch (e) {
    console.log(e);
    const msg = (e.data && e.data.message) || e.message;
    let returnMsg = "";
    // console.log(msg);
    if (msg.includes("unknown account"))
      walletConnect(localStorage.getItem("client") || "");
    else if (msg.includes("user rejected transaction")) returnMsg = "cancel";
    else if (
      msg.includes("cannot estimate gas") ||
      msg.includes("insufficient")
    )
      returnMsg = "Insufficient balance";

    return Promise.reject(returnMsg);
  }
}

// 通用连接合约
export async function connectContract(address, abi) {
  const provider = await getSignedProvider();
  // console.log(provider);
  const cont = new Contract(address, abi, provider.getSigner());
  return cont;
}
