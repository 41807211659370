<template>
  <div class="table-page">
    <div class="page-title">Credit Records</div>
    <table-box class="line" :titles="titles" :api="api"></table-box>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "Home",
  data() {
    return {
      titles: [
        { title: "Credits", name: "coin" },
        { title: "Time", name: "time" }
      ],
      api: api.getRecharges
    };
  }
};
</script>
