import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Discovery from "../views/Discovery.vue";
import My from "../views/My.vue";
import Rankings from "../views/Rankings.vue";
import Invitations from "../views/Invitations.vue";
import Credits from "../views/Credits.vue";
import Dcp from "../views/Dcp.vue";
import Xm from "../views/Xm.vue";
import Game from "../views/Game.vue";

const basename = "";
Vue.use(VueRouter);

const routes = [
  {
    path: "/dcp",
    name: "dcp",
    component: Dcp
  },
  {
    path: "/xm",
    name: "xm",
    component: Xm
  },
  {
    path: "/game",
    name: "game",
    component: Game
  },
  {
    path: "/",
    redirect: "/discovery"
  },
  {
    path: "/home",
    name: "home",
    component: Home
  },
  {
    path: "/discovery",
    name: "discovery",
    component: Discovery
  },
  {
    path: "/my",
    name: "my",
    component: My
  },
  {
    path: "/rankings",
    name: "rankings",
    component: Rankings
  },
  {
    path: "/invitations",
    name: "invitations",
    component: Invitations
  },
  {
    path: "/credits",
    name: "credits",
    component: Credits
  }
];

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置

const router = new VueRouter({
  mode: "history",
  base: basename,
  routes
});

export default router;
