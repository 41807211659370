import "whatwg-fetch"; // fetch polyfill
import "custom-event-polyfill";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import WujieVue from "wujie-vue2";
import hostMap from "../wujie-config/hostMap";
import SvgIcon from "@/components/SvgIcon.vue";
import ModalBox from "@/components/ModalBox.vue";
import TableBox from "@/components/TableBox.vue";
// import lifecycles from "../wujie-config/lifecycle";
// import credentialsFetch from "../wujie-config/fetch";
// import plugins from "../wujie-config/plugin";
import store from "./store";
import "@/assets/css/style.scss";
import { importCommonSvgs } from "./utils";
import message from "@/plugins/message";

importCommonSvgs();

// const isProduction = process.env.NODE_ENV === "production";
const { setupApp, preloadApp, bus } = WujieVue;

Vue.use(WujieVue).use(message);
Vue.component("SvgIcon", SvgIcon);
Vue.component("ModalBox", ModalBox);
Vue.component("TableBox", TableBox);

Vue.config.productionTip = false;

bus.$on("click", (msg) => window.alert(msg));

// 在 xxx-sub 路由下子应用将激活路由同步给主应用，主应用跳转对应路由高亮菜单栏
bus.$on("sub-route-change", (name, path) => {
  const mainName = `${name}-sub`;
  const mainPath = `/${name}-sub${path}`;
  const currentName = router.currentRoute.name;
  const currentPath = router.currentRoute.path;
  if (mainName === currentName && mainPath !== currentPath) {
    router.push({ path: mainPath });
  }
});

/**
 * 配置应用，主要是设置默认配置
 * preloadApp、startApp的配置会基于这个配置做覆盖
 */

setupApp({
  name: "dcp",
  url: hostMap("//localhost:5173/"),
  exec: true,
  sync: true
  // fetch: credentialsFetch
  // plugins
  // ...lifecycles
});
setupApp({
  name: "xm",
  url: hostMap("//localhost:8080/"),
  exec: true,
  sync: true
  // fetch: credentialsFetch,
  // plugins
  // ...lifecycles
});
setupApp({
  name: "game",
  url: hostMap("https://ton-games.net:8088"),
  exec: true,
  sync: true
  // fetch: credentialsFetch
  // plugins
  // ...lifecycles
});

if (window.localStorage.getItem("preload") !== "false") {
  if (window.Proxy) {
    // preloadApp({
    //   name: "dcp"
    // });
    // preloadApp({
    //   name: "game"
    // });
    preloadApp({
      name: "xm"
    });
  }
}
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
