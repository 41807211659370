<template>
  <div class="discovery">
    <img class="banner" src="~@/assets/imgs/banner.png" />
    <div class="nav-list">
      <router-link class="item" to="/xm">
        <img src="~@/assets/imgs/logo.png" alt="" />
        Xmultiverse
      </router-link>
      <router-link class="item" to="/rankings">
        <img src="~@/assets/imgs/credit.png" alt="" />
        Credits Ranking
      </router-link>
    </div>
    <h3>Playing DAPP</h3>
    <div class="app-list">
      <router-link class="item" to="/game">
        <img src="~@/assets/imgs/game.png" /><br />
        Game
      </router-link>
      <router-link class="item" :to="{ path: '/xm', query: { xm: '/nft' } }">
        <img src="~@/assets/imgs/nft.png" /><br />
        NFT
      </router-link>
      <router-link class="item" :to="{ path: '/xm', query: { xm: '/rai' } }">
        <img src="~@/assets/imgs/rai.png" /><br />
        RAI
      </router-link>
      <router-link class="item" to="/dcp">
        <img src="~@/assets/imgs/dcp.png" /><br />
        DCP
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Discovery",
  data() {
    return {};
  },
  watch: {}
};
</script>
