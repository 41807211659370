var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['nft', 'dcp', 'rai', 'game', 'xm'].includes(_vm.$route.name)
      ? 'iframe-page'
      : '',attrs:{"id":"app"}},[(
      ![
        'home',
        'discovery',
        'my',
        'rankings',
        'credits',
        'invitations'
      ].includes(_vm.$route.name)
    )?_c('router-link',{staticClass:"go-home",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/imgs/back.png")}})]):_vm._e(),_c('div',{staticClass:"content",on:{"click":function($event){_vm.active = false}}},[_c('router-view')],1),(['home', 'discovery', 'my', 'rankings'].includes(_vm.$route.name))?_c('div',{staticClass:"footer"},[_c('router-link',{staticClass:"item",class:_vm.$route.name === 'home' ? 'active' : '',attrs:{"to":"/home"}},[_c('svg-icon',{attrs:{"icon":_vm.$route.name === 'home' ? 'home' : 'home-l'}}),_c('br'),_vm._v(" Home ")],1),_c('router-link',{staticClass:"item",class:_vm.$route.name === 'discovery' ? 'active' : '',attrs:{"to":"/discovery"}},[_c('svg-icon',{attrs:{"icon":_vm.$route.name === 'discovery' ? 'discovery' : 'discovery-l'}}),_c('br'),_vm._v(" Discovery ")],1),_c('a',{staticClass:"item",class:_vm.$route.name === 'my' ? 'active' : '',attrs:{"href":"javascript:;"},on:{"click":_vm.goMyPage}},[_c('svg-icon',{attrs:{"icon":_vm.$route.name === 'my' ? 'user' : 'user-l'}}),_c('br'),_vm._v(" My ")],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }