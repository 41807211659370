<template>
  <div class="table-page">
    <div class="page-title">Credits Ranking</div>
    <table-box
      class="line"
      :titles="titles"
      :api="api"
      :limit="30"
      :noPager="true"
    ></table-box>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "Home",
  data() {
    return {
      titles: [
        { title: "Ranking" },
        { title: "Address", name: "Address" },
        { title: "Credits", name: "Coin" }
      ],
      api: api.getCreditRankings
    };
  }
};
</script>
