import Vue from "vue";
import Vuex from "vuex";
import { checkConnect } from "@/wallet/utils";
import api from "@/api";

Vue.use(Vuex);
export default new Vuex.Store({
  mutations: {
    updateUser(state) {
      api
        .getUserInfo()
        .then((user) => {
          state.user = user;
          state.connection = checkConnect();
          return true;
        })
        .catch(() => {
          state.user = {};
          return false;
        });
    },
    connection(state) {
      state.connection = checkConnect();
    }
  },
  state: {
    user: {},
    connection: checkConnect() || ""
  }
});
