export function getFromSite() {
  const str = document.referrer;
  return str.endsWith("/") ? str.slice(0, -1) : str;
}
export function getItem(key) {
  return localStorage.getItem(key);
}
export function setItem(key, value) {
  if (value) localStorage.setItem(key, value);
  else localStorage.removeItem(key);
}
// 存储数据，涉及到跨域存储
export function setStorage(datas) {
  datas.map((i) => {
    setItem(i[0], i[1]);
  });
}

// 钱包相关存储数据
export function getSignData() {
  const account = getItem("account") || "";
  const signData = JSON.parse(getItem("signature") || "{}") || {};
  return signData[account];
}
export function checkConnect() {
  const account = getItem("account");
  const client = getItem("client");
  const res =
    client === "TON"
      ? !account
        ? "login"
        : ""
      : !account
        ? "login"
        : !getSignData()
          ? "sign"
          : "";
  return res;
}

export function setSignature(signature) {
  const signData = JSON.parse(getItem("signature") || "{}") || {};
  setItem(
    "signature",
    JSON.stringify({
      ...signData,
      ...signature
    })
  );
}

export function clearData() {
  const datas = Object.keys(localStorage)
    .filter((i) => i !== "signature")
    .map((i) => [i, ""]);

  datas.map((i) => {
    setItem(i[0], i[1]);
  });
}
export async function clearAllData() {
  const datas = Object.keys(localStorage);
  setStorage(datas.map((i) => [i, ""]));
  localStorage.clear();
}
export function getPlugin(client) {
  return window.ethereum.providers
    ? window.ethereum.providers.find((provider) => provider["is" + client])
    : null;
}

export function setChain(chainId) {
  setItem("chain", chainId ? String(Number(chainId)) : "");
  location.reload();
}

// 字符串转小写
export function strForLower(data) {
  return data.toLowerCase();
}

// 检查链
export function checkChain() {
  const chain = getItem("chain") || "";
  return platform.chains.includes(chain);
}

// 表单
export const inputTypes = {
  phone: /^[0-9]{0,11}$/,
  password: /^[A-Za-z\d@$!%*?&]{0,16}$/,
  code: /^[0-9]{0,6}$/,
  english: /^[A-Za-z0-9\s]{0,100}$/,
  amount: /^([1-9][0-9]*)?$/,
  text: /^[\u4E00-\u9FA5A-Za-z0-9 ]+$/,
  desc: /^[\u4E00-\u9FA5A-Za-z0-9 ,.:;?!，。：；、？！]+$/
};
// export function formmatFunc(val, type){return (!inputTypes[type].test(String(val))) ? val.slice(0, val.length - 1) : val}
export function formmatFunc(val, type) {
  return !inputTypes[type].test(String(val))
    ? val.slice(0, val.length - 1)
    : val;
}
