<template>
  <div
    id="app"
    :class="
      ['nft', 'dcp', 'rai', 'game', 'xm'].includes($route.name)
        ? 'iframe-page'
        : ''
    "
  >
    <router-link
      class="go-home"
      to="/"
      v-if="
        ![
          'home',
          'discovery',
          'my',
          'rankings',
          'credits',
          'invitations'
        ].includes($route.name)
      "
    >
      <img src="~@/assets/imgs/back.png" />
    </router-link>
    <div class="content" @click="active = false">
      <router-view />
    </div>
    <div
      class="footer"
      v-if="['home', 'discovery', 'my', 'rankings'].includes($route.name)"
    >
      <router-link
        class="item"
        :class="$route.name === 'home' ? 'active' : ''"
        to="/home"
      >
        <svg-icon :icon="$route.name === 'home' ? 'home' : 'home-l'"></svg-icon
        ><br />
        Home
      </router-link>

      <router-link
        class="item"
        :class="$route.name === 'discovery' ? 'active' : ''"
        to="/discovery"
      >
        <svg-icon
          :icon="$route.name === 'discovery' ? 'discovery' : 'discovery-l'"
        ></svg-icon
        ><br />
        Discovery
      </router-link>
      <a
        class="item"
        :class="$route.name === 'my' ? 'active' : ''"
        href="javascript:;"
        @click="goMyPage"
      >
        <svg-icon :icon="$route.name === 'my' ? 'user' : 'user-l'"></svg-icon
        ><br />
        My
      </a>
    </div>
  </div>
</template>

<script>
// import store from "@/store";
import { getProvider } from "@/wallet";
import { TonConnectUI } from "@tonconnect/ui";

const tonConnectUI = new TonConnectUI({
  manifestUrl: "https://dcp.xmultiverse.org/manifest.json"
  // buttonRootId: '<YOUR_CONNECT_BUTTON_ANCHOR_ID>'
});
export default {
  name: "App",
  data() {
    return {
      active: false,
      viteFlag: this.$route.name === "vite-sub",
      vue3Flag: this.$route.name === "vue3-sub",
      react17Flag: this.$route.name === "react17-sub",
      degrade: window.Proxy
    };
  },
  watch: {
    $route: {
      handler(r) {
        if (r.name === "xm") {
          const scale = document.documentElement.clientWidth / 10;
          // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
          document.documentElement.style.fontSize =
            (scale >= 200 ? 200 : scale <= 85 ? 85 : scale) + "px";
        } else {
          document.documentElement.style.fontSize = "";
        }
      },
      immediate: true
    },
    "$store.state": {
      handler(v) {
        console.log(v);
      },
      immediate: true
    }
  },
  mounted() {
    const { connection } = this.$store.state;
    if (connection === "") {
      this.$store.commit("updateUser");
    }
    getProvider().then(() => {
      // if (!checkChain())
      //   Message.warn(
      //     "The current network is not supported, please select a supported network"
      //   );
    });
  },
  methods: {
    close() {
      if (this.active) this.active = false;
    },
    handleFlag(name) {
      this[name + "Flag"] = !this[name + "Flag"];
    },
    goMyPage() {
      if (this.$store.state.connection !== "")
        this.$message.warn("Please connect wallet");
      else this.$router.push("/My");
    }
  },
  provide() {
    return {
      tonConnectUI
    };
  }
};
</script>
