<template>
  <!--单例模式，name相同则复用一个无界实例，改变url则子应用重新渲染实例到对应路由 -->
  <WujieVue width="100%" height="100%" name="dcp" :url="dcpUrl"></WujieVue>
</template>

<script>
import hostMap from "../../wujie-config/hostMap";

export default {
  data() {
    return {
      dcpUrl: hostMap("//localhost:5173/")
    };
  }
};
</script>

<style lang="scss" scoped></style>
