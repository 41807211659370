import { ref } from "vue";

export default (api, limit = 10, noPager, params) => {
  let datas = ref([]);
  const pager = ref({ show: false, prev: false, next: false });
  const loading = ref(false);
  const page = ref(1);
  let loaded = false;

  let nextData = ref([]);

  // 分页初始加载
  const getProData = () => {
    page.value = 1;
    pager.value.show = false;
    datas.value = [];
    if (noPager) pager.value = { show: false };
    getData(0, 1).then(async (res) => {
      if (!res) return false;
      datas.value = res;
      if (res.length === limit && !noPager) {
        nextData.value = await getData(page.value + 1);
        const next = !!nextData.value.length;
        pager.value = { show: next, next, prev: false };
      }
    });
  };
  // 分页
  const getNewData = async (p) => {
    if (loaded) return false;
    loaded = true;

    page.value = page.value + p;

    if (p > 0) {
      datas.value = nextData.value;
      nextData.value =
        nextData.value.length === limit ? await getData(page.value + 1) : [];
    } else {
      nextData.value = datas.value;
      datas.value = await getData(0, 1);
    }
    pager.value.next = datas.value.length === limit && !!nextData.value.length;
    pager.value.prev = page.value !== 1;

    loaded = false;
  };

  const getData = (p, needLoad) => {
    if (needLoad) loading.value = true;
    return api({ ...params, pageNo: p || page.value, pageSize: limit })
      .finally(() => {
        if (needLoad) loading.value = false;
      })
      .catch((e) => {
        // console.log(e)
      });
  };

  return { datas, pager, loading, getProData, getNewData };
};
