<template>
  <transition-group name="move-in">
    <div
      :id="'message' + msg.n"
      :class="['message', msg.type]"
      :style="{ top: 20 + 60 * i + 'px' }"
      v-for="(msg, i) in msgs"
      :key="msg.n"
      @click="pop(i)"
      v-html="msg.msg"
    ></div>
  </transition-group>
</template>

<script>
export default {
  name: "Message",
  props: {
    duration: {
      default: 3000,
      type: Number
    }
  },
  data() {
    return {
      type: "open",
      msgs: [],
      n: 1
    };
  },
  methods: {
    open(msg, type) {
      this.n = this.n + 1;
      const msgObj = {
        n: this.n,
        msg: msg.message || msg.msg || msg,
        type: type,
        timer: setTimeout(this.pop, this.duration)
      };
      if (!msgObj.msg) return false;

      this.msgs.push(msgObj);
      return true;
    },
    pop(i = 0) {
      if (this.msgs[i]) clearTimeout(this.msgs[i].timer);
      this.msgs.splice(i, 1);
    }
  }
};
</script>

<style lang="scss">
.message {
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  display: flex;
  align-items: center;
  font-size: 14px;

  border-radius: 4px;
  border: 1px solid;
  padding: 15px 15px;
  transition:
    opacity 0.5s,
    transform 0.4s,
    top 0.4s;

  &.info {
    border-color: #e1f3d8;
    background: #f0f9eb;
    color: #67c23a;
  }
  &.error {
    border-color: #fde2e2;
    background: #fef0f0;
    color: #f56c6c;
  }
  &.warn {
    border-color: #faecd8;
    background: #fdf6ec;
    color: #e6a23c;
  }
}
.move-in-enter-from,
.move-in-leave-to {
  opacity: 0;
  transform: translate(-50%, -100%);
}
</style>
