<template>
  <div class="modal-box">
    <div class="modal">
      <div class="title" v-if="title || showClose">
        {{ title }}
        <svg-icon
          icon="close"
          @click="$emit('close')"
          v-if="showClose !== false"
        ></svg-icon>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    title: String,
    showClose: Boolean
  },
  data() {
    return {
      showRecharge: false
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  unmounted() {
    document.body.style.overflow = "";
  }
};
</script>
