<template>
  <div class="recharge-form">
    <div class="input-box">
      <div class="input-title">Top-up amount ($)</div>
      <div class="input-item">
        <input
          type="text"
          class="input"
          @input="input"
          v-model="inputValue"
          placeholder="Please enter the amount ($1~$10000)"
        />
        <div class="inline-btn" v-show="rechargePoints">
          {{ rechargePoints }} Credits
        </div>
      </div>
    </div>
    <div class="prices-box">
      <div
        class="item"
        :class="{ active: inputValue === k }"
        v-for="(p, k) in prices"
        @click="inputValue = k"
      >
        <p>${{ k }}</p>
        <div class="points">
          <span class="value"
            ><img src="@/assets/imgs/credit.png" alt="" />{{ p.point }}</span
          >
        </div>
        <div class="text">+{{ +p.point - +p.point2 }} credits</div>
      </div>
    </div>
    <div class="coins-box" v-if="!isTON">
      <div
        class="coin"
        @click="selectCoin(c)"
        v-for="(c, k) in coins"
        v-show="showMoreCoins ? true : k === 0"
      >
        <img :src="c.CoinImg" alt="" class="coin-img" v-if="c.CoinImg" />
        <div class="coin-img" v-else>{{ c.CoinName[0] }}</div>
        {{ c.CoinName }}
        <div class="coin-opr" v-if="c.CoinAddr">
          <svg-icon
            icon="copy"
            @click.stop="copyAddress(c.CoinAddr).then(console.success)"
          ></svg-icon>
          <img
            src="@/assets/imgs/metamask2.png"
            @click.stop="
              addAsset(c.CoinAddr, c.CoinName, c.CoinImg, c.Decimals)
            "
            alt=""
          />
        </div>
        <svg-icon
          class="coin-select"
          icon="checked"
          v-if="c.Id === coin.id"
        ></svg-icon>
        <div class="coin-select" v-else></div>
      </div>
      <svg-icon
        class="coin-more"
        :class="{ rotate: showMoreCoins }"
        icon="more"
        @click="showMoreCoins = !showMoreCoins"
      ></svg-icon>
    </div>
    <button class="recharge-btn" @click="pay">Get Started</button>
  </div>
</template>

<script setup>
import { formmatFunc } from "@/wallet/utils";
import { transferCoin } from "@/wallet";
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  onMounted,
  getCurrentInstance,
  inject
} from "vue";
import store from "@/store";
import api from "@/api";
// import { TonConnectUI } from "@tonconnect/ui";

// const tonConnectUI = new TonConnectUI({
//   manifestUrl: "https://dcp.xmultiverse.org/manifest.json"
//   // buttonRootId: '<YOUR_CONNECT_BUTTON_ANCHOR_ID>'
// });
const tonConnectUI = inject("tonConnectUI");

const { proxy } = getCurrentInstance();
const emits = defineEmits(["close", "success", "loading"]);
const props = defineProps({});

const isTON = localStorage.getItem("client") === "TON";
const rules = ref([
  { min: 1, max: 5, price: 0.025 },
  { min: 5, max: 15, price: 0.0238 },
  { min: 15, max: 25, price: 0.0214 },
  { min: 25, price: 0.01724 }
]);

const p = ref(rules.value[0].price);
const prices = rules.value.reduce((pre, i) => {
  if (pre[i.min]) {
    pre[i.min].point = (i.min / i.price).toFixed(0);
    pre[i.min].price = i.price;
  }
  if (i.max) pre[i.max] = { point2: (i.max / p.value).toFixed(0) };
  return pre;
}, {});
const pArr = Object.keys(prices).reverse();

const coins = ref([]);
const coin = ref({ token: "", price: 1700, id: 0 });
const inputValue = ref("");
const loading = ref(false);
const showMoreCoins = ref(true);
const rechargePoints = computed(() => {
  return getPoints(inputValue.value);
});

onMounted(() => {
  if (!isTON)
    api.getRechargeCoins(+localStorage.getItem("chain")).then((res) => {
      console.log(res);
      coins.value = res;
      selectCoin(res[0]);
    });
});
const getCoinPrice = () => {
  api
    .getCoinPrice(+localStorage.getItem("chain"), coin.value.id)
    .then((res) => {
      coin.value.price = res || 1;
    });
};
const selectCoin = (c) => {
  coin.value.id = c.Id;
  coin.value.token = c.CoinAddr;
  coin.value.pay = c.PayAddress;
  coin.value.decimals = c.Decimals;
  coin.value.name = c.CoinName;
  getCoinPrice();
};
const getPoints = (val) => {
  if (!val) return 0;
  const n = pArr.find((i) => val >= +i);
  return n ? (val / prices[n].price).toFixed(0) : (val / p.value).toFixed(0);
};
const pay = async () => {
  if (!inputValue.value) return proxy.$message.error("Please enter the amount");
  if (isTON) TonPay();
  else ETHPay();
};
const ETHPay = () => {
  if (loading.value) return false;
  loading.value = true;
  emits("loading", true);

  transferCoin(
    coin.value.pay,
    Number(inputValue.value / coin.value.price).toFixed(coin.value.decimals),
    coin.value.token,
    coin.value.decimals
  )
    .then(() => {
      emits("success", { points: rechargePoints, money: inputValue.value });
    })
    .catch((e) => {
      console.log(e);
      loading.value = false;
      emits("loading", false);
      // console.log(msg);
      if (e) proxy.$message.error(e);
    });
};
const TonPay = async () => {
  const price = await api.getTonPrice();
  // const price = 5.35;

  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 360,
    messages: [
      {
        address: coin.value.pay,
        amount: String(
          parseInt(String((Number(inputValue.value) / price) * 1000000000))
        )
      }
    ]
  };
  // console.log(transaction);
  // console.log(tonConnectUI);

  try {
    const res = await tonConnectUI.sendTransaction(transaction);
    if (res) {
      emits("success", { points: rechargePoints, money: inputValue.value });
    }
  } catch (error) {
    const msg = error?.message;
    console.log(msg);
    if (
      msg.includes("Reject request") ||
      msg.includes("User rejects") ||
      msg.includes("Pop-up closed")
    ) {
      proxy.$message.error("Reject request");
      emits("loading", false);
    }
  }
};

const input = () => {
  const value = formmatFunc(inputValue.value, "amount");
  if (+value > 10000) inputValue.value = "10000";
  else if (inputValue.value !== value) inputValue.value = value;
};
</script>
