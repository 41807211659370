// 地址省略
export function getAddrStr(str) {
  if (!str) return "";
  return str.slice(0, 6) + "..." + str.slice(-4);
}

// 字符串转小写
export function strForLower(data) {
  if (data) {
    return data.toLowerCase();
  }
}

// 复制用户地址
export function copyAddress(value) {
  // 创建输入框元素
  let oInput = document.createElement("input");
  // 想要复制的值
  oInput.value = value;
  // 页面底部追加输入框
  document.body.appendChild(oInput);
  // 选中输入框
  oInput.select();
  // 执行浏览器复制命令
  document.execCommand("Copy");
  // 复制后移除输入框
  oInput.remove();
  return Promise.resolve("copied");
}

//保留小数
export function toFixed(num, n = 8) {
  const val = Number(num).toFixed(n);
  const val2 = Number(val);
  return val2 ? (val2 <= 0.0000001 ? val : val2) : 0;
}

// 导入svg
export function importCommonSvgs() {
  try {
    const req = require.context("@/assets/icons", false, /\.svg$/);
    req.keys().map(req);
  } catch (error) {
    console.log(error);
  }
}

// 表单
export const inputTypes = {
  phone: /^[0-9]{0,11}$/,
  password: /^[A-Za-z\d@$!%*?&]{0,16}$/,
  code: /^[0-9]{0,6}$/,
  english: /^[A-Za-z0-9\s]{0,100}$/,
  amount: /^([1-9][0-9]*)?$/,
  text: /^[\u4E00-\u9FA5A-Za-z0-9 ]+$/,
  desc: /^[\u4E00-\u9FA5A-Za-z0-9 ,.:;?!，。：；、？！]+$/
};
// export function formmatFunc(val, type){return (!inputTypes[type].test(String(val))) ? val.slice(0, val.length - 1) : val}
export function formmatFunc(val, type) {
  return !inputTypes[type].test(String(val))
    ? val.slice(0, val.length - 1)
    : val;
}
