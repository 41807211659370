<template>
  <div class="table-page">
    <div class="page-title">Invitation Records</div>
    <div class="table-item">
      <div class="item-title">Direct invitation</div>
      <table-box
        class="line"
        :titles="titles"
        :api="api"
        :params="{ source: 8 }"
      ></table-box>
    </div>
    <div class="table-item">
      <div class="item-title">Indirect invitation</div>
      <table-box
        class="line"
        :titles="titles"
        :api="api"
        :params="{ source: 9 }"
      ></table-box>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "Home",
  data() {
    return {
      titles: [
        { title: "Address", name: "address" },
        { title: "Time", name: "time" },
        { title: "Credits", name: "credit" }
      ],
      api: api.getInviteInfo
    };
  }
};
</script>
